import { render, staticRenderFns } from "./PhotoBasedSectionDysfunctionV2.vue?vue&type=template&id=a86cfeb4&scoped=true&"
import script from "./PhotoBasedSectionDysfunctionV2.vue?vue&type=script&lang=js&"
export * from "./PhotoBasedSectionDysfunctionV2.vue?vue&type=script&lang=js&"
import style0 from "./PhotoBasedSectionDysfunctionV2.vue?vue&type=style&index=0&id=a86cfeb4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a86cfeb4",
  null
  
)

export default component.exports