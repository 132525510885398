<template>
  <div class="item flex">
   <div class="item__name">
      {{ $t($options.dysfunctionNameBinding[skinItem.name]) }}
    </div>
    <span class="item__value">
      {{ skinItem.value }}
    </span>
  </div>
</template>

<script>
import { SECTION_DYSFUNCTION_QUESTION_NAME_POSTFIX_BINDING_V2 } from '@/modules/dashboard/api/constants';

export default {
  name: 'PhotoBasedSectionDysfunctionV2',
  dysfunctionNameBinding: SECTION_DYSFUNCTION_QUESTION_NAME_POSTFIX_BINDING_V2,
  props: {
    skinItem: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    colorStyle() {
      return { color: this.color };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.item {
  margin-bottom: 10px;
  flex-direction: column;
  text-align: left;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  &:last-child {
    margin-bottom: 0;
  }

  &__name {
    font-weight: 600;
    color: $text-color;
  }

  &__value {
    font-weight: 300;
  }
}

@media (max-width: 767px) {
  .item {
    &__name {
      width: 65%;
      max-width: 108px;
      margin-right: 10px;
      margin-left: auto;
    }

    &__value {
      width: 100%;
      max-width: 220px;
      margin-right: auto;
    }
  }
}
</style>
