<template>
  <li class="dysfunctions-list__item">
    <div class="dysfunctions-item" :class="dysfunctionItemClasses">
      <slide-down>
        <div
          slot="toggle"
          class="flex--space-between--center"
          :class="{ pointer: !isNoDysfunctionItems }"
          @click="toggleDysfunction"
        >
          <div class="dysfunctions-item__title" :class="{ 'inactive-name': isInactive }">
            {{ $t(`dashboard.diagnostic.${skinDysfunction.name}`) }}
          </div>
          <div class="dysfunctions-item__diagram diagram">
            <div class="diagram__bar" :class="skinDysfunctionSegmentationScore"></div>
            <div class="diagram__label">{{ skinDysfunction.score }}/10</div>
          </div>
          <div class="dysfunctions-item__moreInfo">
            <span v-if="!isNoDysfunctionItems" class="fas fa-chevron-down more-info-icon"></span>
          </div>
        </div>
        <ul
          v-if="!isNoDysfunctionItems"
          slot="slide-body"
          class="dysfunctions-item__description inner-list"
        >
          <li v-if="skinDysfunction.discomfort" class="inner-list__item">
            <div class="inner-list__item-title">
              {{ $t('dashboard.details.discomfortPatient') }}
            </div>
            <div class="inner-list__item-status flex-column">
              <span class="inner-list__item-text">
                {{ $t(`dashboard.diagnosticDetails.${skinDysfunction.discomfort}`) }}
              </span>
            </div>
          </li>
          <component
            :is="dysfunctionSectionItemComponent"
            v-for="skinItem in skinDysfunctionItems"
            :key="skinItem.name"
            :skin-item="skinItem"
            :color="dysfunctionColor"
          ></component>
        </ul>
      </slide-down>
    </div>
  </li>
</template>

<script>
import { isEmpty, kebabCase } from 'lodash';

import SlideDown from '@/modules/dashboard/components/common/SlideDown';
import SkinItemDysfunction from '@/modules/dashboard/components/diagnostic-tab/SkinItemDysfunction';
import PhotoBasedSectionDysfunction from '@/modules/dashboard/components/diagnostic-tab/PhotoBasedSectionDysfunction';

import { DYSFUNCTIONS_COLORS } from '@/modules/dashboard/api/constants';

export default {
  name: 'Dysfunction',
  components: { SkinItemDysfunction, PhotoBasedSectionDysfunction, SlideDown },
  props: {
    skinDysfunction: {
      type: Object,
      required: true
    },
    isQuestionnaireDiagnostic: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      toggle: false
    };
  },
  computed: {
    dysfunctionItemClasses() {
      return [
        { toggle: this.toggle },
        `dysfunctions-item__${kebabCase(this.skinDysfunction.name)}`,
        { 'dysfunctions-item--without-hover': this.isNoDysfunctionItems }
      ];
    },
    dysfunctionColor() {
      return DYSFUNCTIONS_COLORS[this.skinDysfunction.name];
    },
    skinDysfunctionSegmentationScore() {
      return `segmentation${this.skinDysfunction.score}`;
    },
    skinDysfunctionItems() {
      return this.skinDysfunction.items || [];
    },
    isNoDysfunctionItems() {
      return isEmpty(this.skinDysfunctionItems);
    },
    isInactive() {
      return this.skinDysfunction.score < 1;
    },
    dysfunctionSectionItemComponent() {
      return this.isQuestionnaireDiagnostic ? SkinItemDysfunction : PhotoBasedSectionDysfunction;
    }
  },
  methods: {
    toggleDysfunction() {
      if (this.isNoDysfunctionItems) {
        return;
      }

      this.toggle = !this.toggle;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/dysfunctions';
@import '../../../../assets/sass/mixins';

.dysfunctions-item {
  &__moreInfo {
    width: 11px;
    cursor: pointer;
    transition: all 0.1s;

    .more-info-icon {
      color: #c4c4c4;
      font-size: 12px;
    }
  }

  &__sublist {
    width: 100%;
    padding: 15px 0 20px;
  }

  &.toggle {
    border-color: $lavender-color;
    margin-bottom: 10px;

    .dysfunctions-item__moreInfo {
      transform: rotate(180deg);
      transition: all 0.1s;

      .more-info-icon {
        color: $dim-grey-color;
      }
    }

    .dysfunctions-item__description {
      display: block;
    }
  }
}

.inner-list {
  list-style: none;
  padding: 15px 0 10px;

  &__item {
    display: flex;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-title {
    width: 100%;
    max-width: 130px;
    margin-right: 17px;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: $text-color;
    word-break: break-word;
  }

  &__item-status {
    margin-right: 17px;
  }

  &__item-text {
    font-size: 13px;
    line-height: 15px;
  }
}

@media (max-width: 767px) {
  .dysfunctions-item {
    transition: 1s;

    &.toggle {
      border: 1px solid transparent;
      border-radius: 0;
      background-color: $white;
      transition: 1s;
    }
  }

  .inner-list {
    padding: 11px 0 13px;

    &__item {
      padding-right: 10px;
    }

    &__item-title {
      width: 65%;
      max-width: 108px;
      margin-right: 10px;
      margin-left: auto;
    }

    &__item-status {
      width: 100%;
      max-width: 220px;
      margin-right: auto;
    }
  }
}
</style>
